import { Theme } from "./types";

/*
Do not forget to `scripts/dev/frontend_prebuild.sh` after making changes here.
 */

const tixxt: Theme = {
  id: "tixxt",
  name: "tixxt Standard",
  isPreset: true,
  colors: {
    primary: { color: "#99BEDA" },
    secondary: { color: "#412C7C" },
    white: { color: "#FFFFFF" },
    favorite: { color: "#FCD34D" }, // amber-300
    info: { color: "#0284C7" }, // sky-600
    success: { color: "#22C55E" }, // green-500
    warning: { color: "#FACC15" }, // yellow-400
    danger: { color: "#DC2626" }, // red-600

    // "gray" palette
    "gray-50": { color: "#FAFAFA" },
    "gray-100": { color: "#F4F4F5" },
    "gray-200": { color: "#E4E4E7" },
    "gray-300": { color: "#D4D4D8" },
    "gray-400": { color: "#A1A1AA" },
    "gray-500": { color: "#71717A" },
    "gray-600": { color: "#52525B" },
    "gray-700": { color: "#3F3F46" },
    "gray-800": { color: "#27272A" },
    "gray-900": { color: "#18181B" },
  },
  userbarIconType: "light",
  logoType: "hidden",
  bannerType: "hidden",
  bannerImage: null,
  logoImage: null,
  faviconImage: null,
  emailLogoImage: null,
  fullWidth: false,
};

export { tixxt as defaultTheme };

export default [tixxt];
