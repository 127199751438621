import { ColorRule } from "./types";
import { flatMap } from "lodash";

/*
Do not forget to `scripts/dev/frontend_prebuild.sh` after making changes here.
 */

const abstract = true;
const tailwind = true;

/* Global */
let colorRules: ColorRule[] = [
  { name: "primary", abstract, tailwind },
  { name: "secondary", abstract, tailwind },
  { name: "white", abstract, tailwind },
  { name: "neutral", derive: ["val", "gray-300"], abstract, tailwind },
  { name: "favorite", abstract, tailwind },
  { name: "info", abstract, tailwind },
  { name: "success", abstract, tailwind },
  { name: "warning", abstract, tailwind },
  { name: "danger", abstract, tailwind },
  { name: "normal-text", derive: ["val", "gray-800"], tailwind },
  { name: "link-text", derive: ["variant", "primary", 7] },
  { name: "link--hover-text", derive: ["variant", "primary", 8] },
  { name: "unread__content-background", derive: ["variant", "secondary", 0] },
  { name: "banner-background", abstract, derive: ["val", "secondary"] },
];

/* Buttons */
colorRules = colorRules
  .concat(
    scoped("btn-primary", [
      { name: "background", derive: ["val", "primary"] },
      { name: "text", derive: ["mostReadable", "background", "normal-text"] },
      { name: "border", derive: ["val", "background"] },
      { name: "-hover-background", derive: ["shade", "background", 15] },
      { name: "-hover-border", derive: ["shade", "border", 25] },
      {
        name: "-hover-text",
        derive: ["mostReadable", "-hover-background", "normal-text"],
      },
    ]),
  )
  .concat(
    scoped("btn-light", [
      { name: "background", derive: ["val", "white"] },
      { name: "text", derive: ["mostReadable", "background", "normal-text"] },
      { name: "border", derive: ["val", "gray-300"] },
      { name: "-hover-background", derive: ["val", "gray-100"] },
      { name: "-hover-border", derive: ["val", "gray-400"] },
      {
        name: "-hover-text",
        derive: ["mostReadable", "-hover-background", "normal-text"],
      },
    ]),
  )
  .concat(
    flatMap(["danger"], (name) =>
      scoped(`btn-${name}`, [
        { name: "background", derive: ["val", name] },
        { name: "text", derive: ["mostReadable", "background", "normal-text"] },
        { name: "border", derive: ["val", "background"] },
        { name: "-hover-background", derive: ["shade", "background", 15] },
        { name: "-hover-border", derive: ["shade", "border", 25] },
        {
          name: "-hover-text",
          derive: ["mostReadable", "-hover-background", "normal-text"],
        },
      ]),
    ),
  );

/* Alerts */
colorRules = colorRules.concat(
  flatMap(["info", "success", "warning", "danger"], (name) =>
    scoped(`alert-${name}`, [
      { name: "background", derive: ["variant", name, 2] },
      { name: "border", derive: ["variant", name, 4] },
      { name: "text", derive: ["variant", name, 9] },
    ]),
  ),
);

/* Tags */
colorRules = colorRules.concat(
  scoped("tag", [
    { name: "background", derive: ["variant", "primary", 2] },
    { name: "text", derive: ["variant", "primary", 8] },
    { name: "-reader-background", derive: ["variant", "secondary", 2] },
    {
      name: "-reader-text",
      derive: ["variant", "secondary", 8],
    },
  ]),
);

/* ModuleSwitcher */
colorRules = colorRules.concat(
  scoped("module-switcher", [
    { name: "accent", derive: ["val", "primary"], abstract },
    { name: "background", derive: ["val", "gray-200"] },
    { name: "text", derive: ["val", "gray-500"] },
    { name: "-hover-background", derive: ["mix", "background", "accent", 5] },
    { name: "-hover-text", derive: ["mix", "text", "accent", 50] },
    { name: "-active-background", derive: ["val", "accent"] },
    {
      name: "-active-text",
      derive: ["mostReadable", "-active-background", "text"],
    },
  ]),
);

/* UnreadCounter */
colorRules = colorRules.concat(
  scoped("unread-counter", [
    { name: "background", derive: ["val", "secondary"] },
    { name: "text", derive: ["mostReadable", "background", "normal-text"] },
  ]),
);

/* Userbar */
colorRules = colorRules.concat(
  scoped("userbar", [
    { name: "accent", derive: ["val", "primary"], abstract },
    { name: "background", derive: ["val", "gray-100"] },
    { name: "section-background", derive: ["val", "white"] },
    { name: "section__title-text", derive: ["val", "gray-800"] },
    { name: "section__indicator-color", derive: ["val", "gray-500"] },
    { name: "link-text", derive: ["val", "gray-800"] },
    {
      name: "link--hover-background",
      derive: ["mix", "section-background", "accent", 5],
    },
    { name: "link--hover-text", derive: ["mix", "link-text", "accent", 50] },
    { name: "link--active-background", derive: ["val", "accent"] },
    {
      name: "link--active-text",
      derive: ["mostReadable", "link--active-background", "link-text"],
    },
  ]),
);

/* Chat */
colorRules = colorRules.concat(
  scoped("chat", [
    { name: "accent", derive: ["val", "primary"] },
    { name: "accent-contrast", derive: ["mostReadable", "accent", "gray-800"] },
    { name: "background", derive: ["val", "gray-100"] },
    {
      name: "text",
      derive: ["mostReadable", "background", "normal-text"],
      tailwind,
    },
    { name: "header-background", derive: ["val", "gray-200"] },
    {
      name: "header-text",
      derive: ["mostReadable", "header-background", "normal-text"],
      tailwind,
    },
    {
      name: "list--hover-background",
      derive: ["mix", "background", "accent", 5],
    },
    { name: "message-background", derive: ["val", "white"] },
    {
      name: "message-text",
      derive: ["mostReadable", "message-background", "normal-text"],
      tailwind,
    },
    { name: "message--my-background", derive: ["variant", "accent", 1] },
    {
      name: "message--my-text",
      derive: ["mostReadable", "message--my-background", "normal-text"],
      tailwind,
    },
    { name: "composer-background", derive: ["val", "white"] },
    {
      name: "composer-text",
      derive: ["mostReadable", "composer-background", "normal-text"],
      tailwind,
    },
  ]),
);

/* Chat */
colorRules = colorRules.concat(
  scoped("calendar", [
    { name: "today-background", derive: ["variant", "primary", 1] },
  ]),
);

/* Header & Topbar */
colorRules = colorRules.concat(
  scoped("header", [
    { name: "topbar-background", derive: ["val", "gray-800"] },
    {
      name: "topbar-contrast",
      derive: ["mostReadable", "topbar-background", "white", "gray-800"],
    },
    {
      name: "topbar-contrast--hover",
      derive: ["shadeOrTint", "header-topbar-contrast", 25],
    },
    { name: "logo-background", derive: ["val", "white"] },
  ]),
);

/* SearchBar */
colorRules = colorRules.concat(
  scoped("search-bar__input", [
    { name: "background", derive: ["val", "header-topbar-background"] },
    { name: "color", derive: ["val", "header-topbar-contrast"] },
    { name: "border-color", derive: ["val", "header-topbar-contrast--hover"] },
    { name: "-hover-border", derive: ["val", "header-topbar-contrast--hover"] },
    { name: "-focus-background", derive: ["val", "white"] },
    {
      name: "-focus-color",
      derive: ["val", "gray-800"],
    },
    { name: "-focus-border-color", derive: ["val", "header-topbar-contrast"] },
  ]),
);

/* Navigation */
colorRules = colorRules.concat(
  scoped("navigation", [
    { name: "background", derive: ["val", "gray-50"] },
    { name: "text", derive: ["val", "gray-500"] },
    { name: "-hover-text", derive: ["mix", "text", "background", 25] },
  ]),
);

/* BorderBox */
colorRules = colorRules.concat(
  scoped("border-box", [
    { name: "background", derive: ["val", "white"] },
    { name: "border", derive: ["val", "gray-300"] },
  ]),
);

colorRules = colorRules.concat(
  scoped("question", [
    { name: "accent", derive: ["val", "secondary"] },
    { name: "background", derive: ["variant", "accent", 1] },
    { name: "border", derive: ["variant", "accent", 3] },
    { name: "text", derive: ["variant", "accent", 9] },
    { name: "-question-mark", derive: ["variant", "accent", 5] },
    { name: "answer-background", derive: ["val", "background"] },
    { name: "answer-text", derive: ["variant", "accent", 9] },
  ]),
);

/* Mobile App */
colorRules = colorRules.concat(
  scoped("mobile-app", [
    { name: "tab-bar", derive: ["val", "module-switcher-background"] },
    { name: "tab-bar-item", derive: ["val", "module-switcher-text"] },
    {
      name: "tab-bar-item-active",
      derive: ["val", "module-switcher--active-text"],
    },
    {
      name: "tab-bar-item-active-bg",
      derive: ["val", "module-switcher--active-background"],
    },
    { name: "navigation-bar", derive: ["val", "header-topbar-background"] },
    { name: "navigation-bar-text", derive: ["val", "header-topbar-contrast"] },
  ]),
);

/* Channels */
colorRules = colorRules.concat(
  scoped("channel-widgets", [
    { name: "title-background", derive: ["variant", "primary", 4] },
  ]),
);

export { colorRules };

function scoped(scope: string, rules: ColorRule[]): ColorRule[] {
  return rules.map((r) => ({ ...r, scope }));
}
